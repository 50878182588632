<template>
  <modal
    name="new-event"
    height="auto"
    :resizable="true"
    :adaptive="true"
    :scrollable="true"
    @before-open="setDetails"
    @opened="disableScrolling"
    @closed="enableScrolling"
    classes="bg-white"
  >
    <h2
      class="text-2xl relative flex justify-between items-center font-bold bg-primary text-white py-5 px-6"
    >
      <span>Vytvoření rezervace</span>
      <span class="modal-close" @click="closeModal"><x-icon size="24" /></span>
    </h2>
    <form @submit.prevent="createEvent" class="px-6 pb-6 pt-5">
      <div v-if="globalErr" class="bg-red-600 text-white px-6 py-3 mb-4">
        <p>{{ globalErr }}</p>
      </div>
      <label class="block mb-2">
        <span
          class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-bold"
        >
          Jméno rezervace
        </span>
        <input
          type="text"
          name="name"
          class="input"
          placeholder="Pojmenuj rezervaci..."
          v-model="name"
          @change="validateName"
        />
        <p class="text-red-500 text-sm mt-1" v-if="nameErr">
          {{ nameErr }}
        </p>
      </label>
      <div class="mb-3">
        <p class="text-sm">
          <strong>Opakování rezervace</strong>
        </p>
        <div class="flex justify-between items-center">
          <div class="mt-1">
            <input
              @change="validateRepeat"
              v-model="repeat"
              type="checkbox"
              id="repeat"
              name="repeat"
            />
            <label for="repeat" class="select-none checkbox">Opakovat</label>
          </div>
          <div v-if="repeat" class="flex gap-4 flex-wrap mt-1">
            <div>
              <input
                v-model="repeatOn[1]"
                type="checkbox"
                id="monday"
                name="monday"
                @change="validateRepeat"
              />
              <label for="monday" class="select-none checkbox">Po</label>
            </div>
            <div>
              <input
                v-model="repeatOn[2]"
                type="checkbox"
                id="tuesday"
                name="tuesday"
                @change="validateRepeat"
              />
              <label for="tuesday" class="select-none checkbox">Út</label>
            </div>
            <div>
              <input
                v-model="repeatOn[3]"
                type="checkbox"
                id="wednesday"
                name="wednesday"
                @change="validateRepeat"
              />
              <label for="wednesday" class="select-none checkbox">St</label>
            </div>
            <div>
              <input
                v-model="repeatOn[4]"
                type="checkbox"
                id="thursday"
                name="thursday"
                @change="validateRepeat"
              />
              <label for="thursday" class="select-none checkbox">Čt</label>
            </div>
            <div>
              <input
                v-model="repeatOn[5]"
                type="checkbox"
                id="friday"
                name="friday"
                @change="validateRepeat"
              />
              <label for="friday" class="select-none checkbox">Pá</label>
            </div>
          </div>
        </div>
        <div class="mt-2 mb-3" v-if="repeat">
          <div class="flex items-center gap-3">
            <p>{{ repeatEvery === "1" ? "každý" : "každé" }}</p>
            <select class="flex-grow-0 w-auto" v-model="repeatEvery">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            <p>{{ repeatEvery === "1" ? "týden" : "týdny" }}</p>
          </div>
        </div>
        <p class="text-red-500 text-sm mt-1" v-if="repeatErr">
          {{ repeatErr }}
        </p>
      </div>
      <div class="flex gap-3">
        <label class="block mb-2 flex-grow basis-0 w-1/2">
          <span
            class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-bold"
          >
            {{ repeat ? "Opakovat od" : "Datum od" }}
          </span>
          <input
            name="name"
            class="input"
            type="date"
            :max="maxDate"
            :min="minDate"
            placeholder="15. 01. 2022"
            v-model="dateFrom"
            @change="validateDateFrom"
          />
          <p class="text-red-500 text-sm mt-1" v-if="dateFromErr">
            {{ dateFromErr }}
          </p>
        </label>
        <label class="block mb-2 flex-grow basis-0 w-1/2">
          <span
            class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-bold"
          >
            {{ repeat ? "Opakovat do" : "Datum do" }}
          </span>
          <input
            name="name"
            type="date"
            class="input"
            :max="maxDate"
            :min="minDate"
            :placeholder="dateFrom"
            v-model="dateTo"
            @change="validateDateTo"
          />
          <p class="text-red-500 text-sm mt-1" v-if="dateToErr">
            {{ dateToErr }}
          </p>
        </label>
      </div>
      <div class="flex gap-3">
        <label class="block mb-2 flex-grow basis-0 w-1/2">
          <span
            class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-bold"
          >
            Čas od
          </span>
          <input
            type="time"
            name="name"
            class="input"
            :placeholder="timeFromPlaceholder"
            v-model="timeFrom"
            @change="validateTimeFrom"
          />
          <p class="text-red-500 text-sm mt-1" v-if="timeFromErr">
            {{ timeFromErr }}
          </p>
        </label>
        <label class="block mb-2 flex-grow basis-0 w-1/2">
          <span
            class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-bold"
          >
            Čas do
          </span>
          <input
            type="time"
            name="name"
            class="input"
            :placeholder="timeToPlaceholder"
            v-model="timeTo"
            @change="validateTimeTo"
          />
        </label>
      </div>
      <div class="flex gap-3">
        <label class="block mb-2 flex-grow basis-0 w-1/2">
          <span
            class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-bold"
          >
            Počet lidí
          </span>
          <input
            name="name"
            type="number"
            class="input h-[48px]"
            min="1"
            max="25"
            v-model="people"
          />
        </label>
        <!-- <label class="block mb-2 flex-grow basis-0 w-1/2">
          <span
            class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-bold"
          >
            Potřeba technika
          </span>

          <select v-model="techNeeded" class="input">
            <option value="true">Ano</option>
            <option value="false">Ne</option>
          </select>
          <p class="text-red-500 text-sm mt-1" v-if="dateFromErr">
            {{ dateFromErr }}
          </p>
        </label> -->
      </div>
      <label class="block mb-2">
        <span
          class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-bold"
        >
          Poznámka
        </span>
        <input
          type="text"
          name="comment"
          class="input"
          placeholder="Zadej poznámku..."
          v-model="comment"
        />
      </label>
      <button :disabled="loading" class="btn mt-3 w-full" type="submit">
        Vytvořit rezervaci
      </button>
    </form>
  </modal>
</template>

<script>
import { XIcon } from "vue-tabler-icons"
import Moment from "moment"
import PocketBase from "pocketbase"
import { useAuthStore } from "../stores/AuthStore"
import { extendMoment } from "moment-range"
import { useEventsStore } from "../stores/EventsStore"

const moment = extendMoment(Moment)

const pb = new PocketBase(process.env.VUE_APP_POCKETBASE_URL)

function initialState() {
  return {
    name: "",
    dateFrom: "",
    dateTo: "",
    timeFrom: "",
    timeTo: "",
    comment: "",
    people: 1,
    techNeeded: true,

    repeat: false,
    repeatOn: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
    },

    nameErr: null,
    dateFromErr: null,
    timeFromErr: null,
    timeToErr: null,
    dateToErr: null,
    repeatErr: null,

    loading: false,

    repeatEvery: 1,

    dateDiff: 0,

    globalErr: null,

    timeToPlaceholder: null,
    timeFromPlaceholder: null,

    maxDate: moment().add(1, "year").format("YYYY-MM-DD"),
    minDate: moment().format("YYYY-MM-DD"),
  }
}

export default {
  name: "NewEventModal",
  props: ["events", "event"],
  data() {
    return initialState()
  },
  methods: {
    closeModal() {
      this.$modal.hide("new-event")
    },
    validateName() {
      if (!this.name) {
        this.nameErr = "Jméno rezervace je povinné"
      } else {
        this.nameErr = null
      }
    },
    validateDateFrom() {
      if (this.dateTo) {
        this.dateDiff = moment(this.dateTo).diff(this.dateFrom, "days")
      }
      if (!this.dateFrom) {
        this.dateFromErr = "Datum začátku je povinné"
      } else if (!moment(this.dateFrom, "YYYY-MM-DD").isValid()) {
        this.dateFromErr = "Datum je ve špatném formátu"
      } else if (
        moment(this.dateFrom, "YYYY-MM-DD").isBefore(
          moment().subtract(1, "days")
        )
      ) {
        this.dateFromErr = "Datum nesmí být v minulosti"
      } else if (
        moment(this.dateFrom).day() === 0 ||
        moment(this.dateFrom).day() === 6
      ) {
        this.dateFromErr = "Datum rezervace nesmí být v sobotu nebo neděli"
      } else {
        this.dateFromErr = null
      }
    },
    validateDateTo() {
      if (this.dateFrom) {
        this.dateDiff = moment(this.dateTo).diff(this.dateFrom, "days")
      }
      if (this.dateTo && !moment(this.dateTo, "YYYY-MM-DD").isValid()) {
        this.dateToErr = "Datum je ve špatném formátu"
      } else if (
        this.dateTo &&
        moment(this.dateTo, "YYYY-MM-DD").isBefore(moment().subtract(1, "days"))
      ) {
        this.dateToErr = "Datum nesmí být v minulosti"
      } else if (
        this.dateTo &&
        moment(this.dateTo, "YYYY-MM-DD").isBefore(this.dateFrom)
      ) {
        this.dateToErr = "Datum nesmí být dříve než datum začátku"
      } else if (this.repeat && !this.dateTo) {
        this.dateToErr = "Při opakování je nutné zadat datum konce."
      } else {
        this.dateToErr = null
      }
    },
    validateTimeFrom() {
      this.getToTime()
      if (!this.timeFrom) {
        this.timeFromErr = "Čas začátku je povinný"
      } else if (!moment(this.timeFrom, "HH:mm").isValid()) {
        this.timeFromErr = "Čas je ve špatném formátu"
      } else if (
        this.timeFrom &&
        moment(this.timeFrom, "HH:mm").isAfter(moment(this.timeTo, "HH:mm"))
      ) {
        this.timeFromErr = "Čas nesmí být později než čas konce"
      } else {
        this.timeFromErr = null
      }
    },
    validateTimeTo() {
      if (!this.timeTo) {
        this.timeToErr = "Čas konce je povinný"
      } else if (!moment(this.timeTo, "HH:mm").isValid()) {
        this.timeToErr = "Čas je ve špatném formátu"
      } else {
        this.timeToErr = null
      }
    },
    validateAll() {
      //this.validateName();
      this.validateDateFrom()
      //this.validateDateTo();
      this.validateTimeFrom()
      this.validateTimeTo()
      this.validateRepeat()
    },
    validateRepeat() {
      if (this.repeat) {
        if (
          !this.repeatOn[1] &&
          !this.repeatOn[2] &&
          !this.repeatOn[3] &&
          !this.repeatOn[4] &&
          !this.repeatOn[5]
        ) {
          this.repeatErr =
            "Opakující se rezervace se musí opakovat aspoň v jeden den."
        } else {
          this.repeatErr = null
        }
      } else {
        this.repeatErr = null
      }
    },
    async createEvent(e) {
      e.preventDefault()
      this.loading = true
      this.validateName()
      this.validateDateFrom()
      this.validateDateTo()
      this.validateTimeFrom()
      this.validateTimeTo()

      if (
        this.nameErr ||
        this.dateFromErr ||
        this.timeFromErr ||
        this.timeToErr ||
        this.dateToErr ||
        this.repeatErr
      ) {
        this.loading = false
        return
      }

      let dateFrom = moment(this.dateFrom, "YYYY-MM-DD")
      let dateTo = moment(this.dateTo, "YYYY-MM-DD")
      let startTime = moment(this.timeFrom, "HH:mm")
      let endTime = moment(this.timeTo, "HH:mm")
      let days

      if (dateTo && dateTo.diff(dateFrom, "days") > 0) {
        days = dateTo.diff(dateFrom, "days") + 1

        if (days > 90) {
          this.globalErr = "Nelze vytvořit rezervace delší než tři měsíce."
          this.loading = false
          return
        }
      } else {
        days = 1
      }

      let dateLocal

      let hasError = false

      let localEvents = []

      let week = dateFrom.week()

      let createEventLocal = async (index) => {
        dateLocal = dateFrom.clone().add(index, "days")
        let weekLocal = dateLocal.week()

        if (dateLocal.day() === 0 || dateLocal.day() === 6) {
          return
        }

        if (days > 1 && this.repeat) {
          for (let i = 0; i <= 5; i++) {
            if (!this.repeatOn[i] && i === dateLocal.day()) {
              return
            }
          }
        }

        if (this.repeat && this.repeatEvery > 1) {
          if ((weekLocal - week) % this.repeatEvery !== 0) {
            return
          }
        }

        const rangeNew = moment.range(
          moment(
            `${dateLocal.format("YYYY-MM-DD")} ${this.timeFrom}`,
            "YYYY-MM-DD HH:mm"
          ),
          moment(
            `${dateLocal.format("YYYY-MM-DD")} ${this.timeTo}`,
            "YYYY-MM-DD HH:mm"
          )
        )

        await this.eventsStore.events.forEach((event) => {
          const eventDate = moment(event.date).format("YYYY-MM-DD")

          const rangeEvent = moment.range(
            moment(`${eventDate} ${event.startTime}`, "YYYY-MM-DD HH:mm"),
            moment(`${eventDate} ${event.endTime}`, "YYYY-MM-DD HH:mm")
          )

          let intersect = rangeNew.intersect(rangeEvent)

          if (intersect) {
            this.globalErr = `Rezervace se překrývá s rezervace ${
              event.name
            } ve dne ${moment(event.date).format(
              "DD.MM.YYYY"
            )} v čase od ${intersect.start.format(
              "HH:mm"
            )} do ${intersect.end.format("HH:mm")}.`
            hasError = true
            this.loading = false
          }
        })

        if (hasError) {
          return
        }

        const payload = {
          name: this.name,
          author: this.authStore.authData.record.id,
          date: dateLocal,
          startTime: startTime,
          endTime: endTime,
          comment: this.comment,
          repeat: this.repeat,
          people: this.people,
          techNeeded: this.techNeeded,
        }

        const record = await pb.collection("events").create(payload, {
          expand: "author",
          $autoCancel: false,
        })

        localEvents.push(record)

        this.eventsStore.pushEvent({
          ...payload,
          id: record.id,
          expand: record.expand,
          startTime: startTime.format("HH:mm"),
          endTime: endTime.format("HH:mm"),
        })
      }

      for (let i = 0; i < days; i++) {
        await createEventLocal(i)
      }

      if (localEvents.length > 1) {
        await localEvents.forEach((event) => {
          pb.collection("events").update(event.id, {
            sameEvent: [
              ...localEvents.filter((e) => e.id !== event.id).map((e) => e.id),
            ],
          })
        })
      }

      if (hasError) {
        return
      }

      if (localEvents.length === 0) {
        this.globalErr =
          "S tímto nastavením nebude vytvořena žádná rezervace. Zkontroluj nastavení opakování."
        this.loading = false
        return
      }

      this.$modal.hide("new-event")

      Object.assign(this.$data, initialState())

      this.loading = false

      this.$toasted.show(`Rezervace vytvořena.`, { type: "success" })
    },
    setDetails({ params }) {
      Object.assign(this.$data, initialState())
      this.dateFrom = params?.dateClicked.format("YYYY-MM-DD")
      this.timeFromPlaceholder = moment()
        .add(60, "minutes")
        .startOf("hour")
        .format("HH:mm")
      this.timeFrom = params?.dateClicked
        .add(30, "minutes")
        .startOf("hour")
        .format("HH:mm")
      if (this.dateFrom) {
        this.validateAll()
      }
    },
    getToTime() {
      if (this.timeFrom && !this.timeTo) {
        this.timeTo = moment(this.timeFrom, "HH:mm")
          .add(30, "minutes")
          .format("HH:mm")
      }
    },
    disableScrolling() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      // if any scroll is attempted, set this to the previous value
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop)
      }
    },
    enableScrolling() {
      window.onscroll = function () {}
    },
  },
  setup() {
    const authStore = useAuthStore()
    const eventsStore = useEventsStore()

    return {
      authStore,
      eventsStore,
    }
  },
  components: {
    XIcon,
  },
}
</script>
