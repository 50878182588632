<template>
  <modal
    name="event-detail"
    height="auto"
    :resizable="true"
    :adaptive="true"
    :scrollable="true"
    @before-open="setDetails"
    @opened="disableScrolling"
    @closed="enableScrolling"
    classes="bg-white"
  >
    <div
      class="relative flex justify-between items-center bg-primary text-white py-5 px-6"
    >
      <div class="flex flex-col">
        <h2 class="font-bold text-2xl">Detail rezervace</h2>
        <p>
          {{ event?.name }}
          <span class="opacity-75"
            >({{ getDate(event?.date) }} {{ event?.startTime }}–{{
              event?.endTime
            }})</span
          >
        </p>
      </div>
      <span class="modal-close" @click="closeModal"><x-icon size="24" /></span>
    </div>
    <div class="px-6 py-5">
      <p class="mb-4">
        <strong>Název rezervace:</strong><br />{{ event?.name }}
      </p>

      <div class="grid grid-cols-2 gap-6 mb-4">
        <p>
          <strong>Vytvořil/a:</strong><br />{{ event?.expand?.author?.name }}
        </p>
        <p>
          <strong>Počet lidí:</strong><br />{{ event?.people }}
        </p>
        <!-- <p>
          <strong>Potřeba technika:</strong><br />{{
            event?.techNeeded ? "Ano" : "Ne"
          }}
        </p> -->
      </div>
      <p class="mb-3" v-if="event.comment">
        <strong>Poznámka:</strong><br /><span>{{ event.comment }}</span>
      </p>
      <p class="mb-1" v-if="event?.expand?.sameEvent">
        <strong>Související rezervace:</strong><br />
      </p>
      <ul>
        <li
          v-for="event in event?.expand?.sameEvent?.slice(0, 5)"
          :key="event.id"
        >
          {{ event.name }} ({{ parseOccuring(event) }})
        </li>
        <li v-if="event?.expand?.sameEvent?.length > 5">
          <em>... {{ event?.expand?.sameEvent?.length - 5 }} dalších</em>
        </li>
      </ul>
      <div class="flex gap-2 mt-2">
        <button
          @click="editOneEvent"
          v-if="canManage()"
          class="btn w-full mt-5"
        >
          Upravit rezervaci
        </button>
        <button
          @click="editEvent"
          v-if="canManage() && event?.sameEvent?.length > 0"
          class="btn w-full mt-5"
        >
          Upravit všechny v řadě
        </button>
      </div>

      <div class="flex gap-2 mt-2">
        <button
          v-if="canManage() && !confirm"
          @click="confirmOne ? deleteEvent() : (confirmOne = true)"
          :class="`btn w-full btn-red ${confirmOne && 'btn-red-active'}`"
          type="submit"
        >
          {{ confirmOne ? "Opravdu smazat rezervaci?" : "Smazat rezervaci" }}
        </button>
        <button
          v-if="confirmOne"
          @click="confirmOne = false"
          class="btn btn-cube"
        >
          <x-icon />
        </button>
        <button
          v-if="canManage() && event?.sameEvent?.length > 0 && !confirmOne"
          @click="confirm ? deleteOccuring() : (confirm = true)"
          :class="`btn w-full btn-red ${confirm && 'btn-red-active'}`"
          type="submit"
        >
          {{
            confirm ? "Opravdu smazat všechny v řadě?" : "Smazat všechny v řadě"
          }}
        </button>
        <button v-if="confirm" @click="confirm = false" class="btn btn-cube">
          <x-icon />
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { XIcon } from "vue-tabler-icons"
import moment from "moment"
import { useAuthStore } from "../stores/AuthStore"
import PocketBase from "pocketbase"
import { useEventsStore } from "../stores/EventsStore"

const pb = new PocketBase(process.env.VUE_APP_POCKETBASE_URL)

export default {
  name: "EventDetailModal",
  props: ["events"],
  data() {
    return {
      event: {},
      confirmOne: false,
      confirm: false,
    }
  },
  setup() {
    const authStore = useAuthStore()
    const eventsStore = useEventsStore()

    return {
      authStore,
      eventsStore,
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide("event-detail")
      this.confirmOne = false
      this.confirm = false
    },
    setDetails({ params }) {
      pb.collection("events")
        .getOne(params.id, { expand: "sameEvent,author" })
        .then((event) => {
          event.startTime = moment(event.startTime).format("HH:mm")
          event.endTime = moment(event.endTime).format("HH:mm")
          this.event = event
        })
    },
    editEvent() {
      this.$modal.show("edit-event", {
        event: this.event,
      })
      this.closeModal()
    },
    editOneEvent() {
      this.$modal.show("edit-one-event", {
        event: this.event,
      })
      this.closeModal()
    },
    getDate(date) {
      return moment(date).format("DD. M. YYYY")
    },
    getTime(time) {
      return moment(time).format("HH:mm")
    },
    deleteEvent() {
      pb.collection("events")
        .delete(this.event.id)
        .then(() => {
          this.eventsStore.deleteEvent(this.event)
          this.closeModal()
          this.$toasted.show(`Rezervace smazána.`, {
            type: "success",
          })
        })
        .catch((err) => {
          this.$toasted.show(`Nepodařilo se smazat rezervaci.`, {
            type: "error",
          })
          console.log(err)
        })
    },
    deleteOccuring() {
      pb.collection("events")
        .delete(this.event.id)
        .then(async () => {
          await this.event.sameEvent.forEach((e) => {
            this.eventsStore.events.forEach((event) => {
              if (event.id === e) {
                if (moment(event.date).isAfter(moment().subtract(1, "day"))) {
                  pb.collection("events").delete(e)
                  this.eventsStore.deleteEvent(e)
                }
              }
            })
          })
          this.eventsStore.deleteEvent(this.event)
          this.closeModal()
          this.$toasted.show(`Rezervace smazána.`, {
            type: "success",
          })
        })
        .catch((err) => {
          this.$toasted.show(`Nepodařilo se smazat rezervaci.`, {
            type: "error",
          })
          console.log(err)
        })
    },
    parseOccuring(event) {
      return moment(event.date).format("DD. M. YYYY")
    },
    canManage() {
      return (
        this.event?.author === this.authStore.authData.record.id ||
        (this.authStore.authData.record.team ===
          this.event?.expand?.author?.team &&
          this.authStore.authData.record.isDepartmentHead) ||
        this.authStore.authData.record.isSuperAdmin
      )
    },
    disableScrolling() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      // if any scroll is attempted, set this to the previous value
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop)
      }
    },
    enableScrolling() {
      window.onscroll = function () {}
    },
  },
  components: {
    XIcon,
  },
}
</script>
