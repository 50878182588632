<template>
    <div>
        <header>
            <div class="title-bar bg-primary py-2">
                <p
                    class="flex gap-2 px-4 items-center max-w-screen-xl mx-auto font-muni"
                >
                    <!-- <globe-icon />  -->MUNI CZS
                </p>
            </div>
            <div
                class="title max-w-screen-xl flex justify-between items-center mx-auto px-4 py-12"
            >
                <h1 class="text-4xl font-bold">
                    Rezervace místnosti<br />VirtuEDU
                </h1>
                <div class="flex items-center gap-2">
                    <div
                        class="w-12 h-12 avatar flex justify-center text-xl tracking-tighter items-center bg-primary text-white font-muni rounded-full overflow-hidden select-none"
                    >
                        <p>
                            {{
                                authStore.authData.record.name
                                    .match(/(^\S\S?|\s\S)?/g)
                                    .map((v) => v.trim())
                                    .join("")
                                    .match(/(^\S|\S$)?/g)
                                    .join("")
                                    .toLocaleUpperCase()
                            }}
                        </p>
                    </div>
                    <div>
                        <p class="text-md font-bold">
                            {{ authStore.authData.record.name }}
                        </p>
                        <p class="text-sm text-gray-500">
                            {{ team }}
                        </p>
                    </div>
                    <div
                        class="text-red-500 p-2 cursor-pointer hover:text-red-700 duration-150"
                        title="Odhlásit se"
                    >
                        <LogoutIcon @click="signOut" />
                    </div>
                </div>
            </div>
        </header>
        <vue-scheduler
            :events="eventsStore.events"
            :event-display="eventDisplay"
            :event-dialog-config="dialogConfig"
            :disable-dialog="true"
            :initial-view="this.initialView"
            @time-clicked="checkTime"
            @day-clicked="checkTime"
            @event-clicked="eventClicked"
            @view-changed="viewChanged"
        />
        <NewEventModal :events="events" />
        <EventDetailModal :events="events" />
        <edit-event-modal />
        <edit-one-event-modal />
    </div>
</template>

<script>
import moment from "moment"
import NewEventModal from "./NewEventModal.vue"
import EventDetailModal from "./EventDetailModal.vue"
import PocketBase from "pocketbase"
import { LogoutIcon } from "vue-tabler-icons"
import { useAuthStore } from "../stores/AuthStore"
import { useEventsStore } from "../stores/EventsStore"
import EditEventModal from "./EditEventModal.vue"
import EditOneEventModal from "./EditOneEventModal.vue"

const pb = new PocketBase(process.env.VUE_APP_POCKETBASE_URL)

const teams = {
    970000: "Vedení",
    970091: "Projektové oddělení",
    970092: "Oddělení pro krátkodobé a inovativní programy",
    970093: "Oddělení pro mezinárodní marketing",
    970094: "IT oddělení",
    970095: "Welcome Office",
    970096: "Oddělení pro mobility",
    970097: "Oddělení administrativní a ekonomické podpory",
}

export default {
    name: "MainLogged",
    data() {
        return {
            dialogConfig: {
                title: "Rezervace",
                createButtonLabel: "Potvrdit rezervaci",
                fields: [
                    {
                        name: "name",
                        label: "Název",
                    },
                    {
                        name: "comment",
                        type: "textarea",
                        label: "Komentář",
                    },
                    {
                        name: "by",
                        type: "hidden",
                        showLabel: false,
                        value: "TBA",
                    },
                ],
            },
            events: [],
            team: null,
        }
    },
    setup() {
        const authStore = useAuthStore()
        const eventsStore = useEventsStore()
        const initialView = localStorage.view || "month"

        eventsStore.getData()

        return {
            authStore,
            eventsStore,
            initialView,
        }
    },
    mounted() {
        this.authStore.getCurrentData()
        this.team = teams[this.authStore.authData.record.team]
    },
    methods: {
        eventClicked(event) {
            if (moment(event.date).add(1, "day").isBefore(moment())) return
            this.$modal.show("event-detail", event)
        },
        signOut() {
            this.authStore.$patch({ authData: null })
            pb.authStore.clear()
        },
        async checkTime(event) {
            let date

            if (event.time) {
                date = await moment(event.date)
                if (date.day() === 0 || date.day() === 6) {
                    return
                }
                date = await date.hour(event.time).minutes(0).seconds(0)
            } else {
                if (event.time === null) {
                    date = await moment(event.date)
                } else {
                    date = await moment(event)
                }

                if (date.day() === 0 || date.day() === 6) {
                    return
                }
            }

            if (date.isBefore(moment().minutes(0).seconds(0))) {
                return
            }

            this.$modal.show("new-event", {
                dateClicked: date,
                events: this.events,
            })
        },
        eventDisplay(event) {
            return (
                event.name +
                " (" +
                (event.author === this.authStore.authData.record.id
                    ? "<strong>" + event.expand.author.name + "</strong>"
                    : event.expand.author.name) +
                ")</p>"
            )
        },
        viewChanged(view) {
            localStorage.setItem("view", view)
        },
    },
    components: {
        NewEventModal,
        EventDetailModal,
        LogoutIcon,
        EditEventModal,
        EditOneEventModal,
    },
}
</script>

<style>
.v-cal-content .v-cal-event-item .v-cal-event-by {
    color: white;
}

.v-cal-content .v-cal-event-item .v-cal-event-name {
    margin-left: 0 !important;
}
</style>
