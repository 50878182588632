import { defineStore } from "pinia"
import PocketBase from "pocketbase"
import moment from "moment"

const pb = new PocketBase(process.env.VUE_APP_POCKETBASE_URL)

export const useEventsStore = defineStore("EventsStore", {
    state: () => {
        return {
            events: []
        }
    },
    actions: {
        async getData() {
            const events = await pb.collection("events").getFullList(200, {
                sort: "created",
                expand: ["author"],
            })
            events.forEach((event) => {
                event.startTime = moment(event.startTime).format("HH:mm")
                event.endTime = moment(event.endTime).format("HH:mm")
            })
            this.events = events
        },
        pushEvent(event) {
            this.events.push(event)
        },
        deleteEvent(event) {
            let id = event.id || event;
            this.events = this.events.filter((e) => e.id !== id)
        }
    },
})
